import produce from "immer"
const initialState = {
    createdCamp: {
        campaignName: "",
    },
    campaign: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_CAMPAIGN_NAME":
            return produce(state, (draft) => {
                draft.createdCamp = {
                    ...draft.createdCamp,
                    campaignName: action.payload.campaignName,
                    campaignId: action.payload.campaignId
                }

            })
        // case "SET_VIDEOSET_DATA":
        //     return produce(state, (draft) => {
        //         draft.createdCamp = {
        //             ...draft.createdCamp,
        //             videoSetId: action.payload.id,
        //             dimension: action.payload.dimension,
        //             keyword: action.payload.keyword,
        //             videoSetName: action.payload.name
        //         }
        //     })

        //for Editor 


        case "ADD_SCRIPT_DATA":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                draft.campaign = data
                draft.campaign = {
                    ...draft.campaign,
                    videoId: data.id
                }
                delete draft.campaign.id
            })

        case "ADD_VIDEO_DATA":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                draft.campaign = data
                draft.campaign = {
                    ...draft.campaign,
                    undoRedo: []
                }
                if ((data.slides.findIndex(({ isSelected }) => +isSelected === 1)) === -1) {
                    data.slides[0].isSelected = "1"
                    data.slides[0].layers[0].isSelected = true
                    data.slides[0].background.isSelected = 1
                }
                let pos = 0
                data.slides.forEach((curElem) => {
                    // curElem.faceNarration = JSON.parse(curElem.faceNarration)
                    curElem.layers.forEach((layer) => {
                        if (!layer.textToHighlight && layer.type === "text") {
                            layer.textToHighlight = []
                        }
                        if (layer.type === "video" && layer.mute === undefined) {
                            layer.mute = true
                        }
                        if (layer.type === "text") {
                            if (layer.style.lineHeight === "") {
                                layer.style.lineHeight = 1.4
                            }
                        }
                    })
                    if (!curElem.timelineData) {
                        curElem.timelineData = {
                            width: curElem.duration * 100,
                            xVal: pos
                        }
                        pos = pos + 3 + curElem.duration * 100
                    }
                    draft.campaign.undoRedo.push({
                        slideId: curElem.id,
                        past: [],
                        present: curElem,
                        future: []
                    })
                })

            })

        case 'UPDATE_SLIDES_DATA':
            return produce(state, (draft) => {
                draft.campaign.lastAction = "UPDATE_SLIDES_DATA"
                draft.campaign.slides[action.payload.index].isSelected = "1"
                draft.campaign.slides[action.payload.slideIndex].isSelected = "0"
                if (draft.campaign.slides[action.payload.index].layers) {
                    draft.campaign.slides[action.payload.index].layers.forEach((curElem) => {
                        curElem.animation.playAnimation = true
                    })
                }

            })
        case "REARRANGE TILES":
            return produce(state, (draft) => {
                // const startSlide = draft.campaign.slides[action.payload.startIndex]
                // const endSlide = draft.campaign.slides[action.payload.endIndex]
                // const [removed] = draft.campaign.slides.splice(action.payload.startIndex, 1);
                // draft.campaign.slides.splice(action.payload.endIndex, 0, removed);
                // draft.campaign.slides.forEach((curElem, index) => {
                //     if (action.payload.endIndex >= index && action.payload.startIndex !== index) {
                //         curElem.timelineData.xVal = endSlide.timelineData.width
                //     } else if (index === action.payload.startIndex) {
                //         curElem.timelineData.xVal = endSlide.timelineData.xVal
                //     }
                // })
                draft.campaign.slides = action.payload
            })

        case "ON_TEXT_FONTSIZE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontSize = action.payload.data
            })
        case "ON_TEXT_FONTSTYLE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontFamily = action.payload.data
            })
        case "ON_TEXT_FONTBOLD":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontWeight = action.payload.data
            })
        case "ON_TEXT_FONTITALIC":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.fontStyle = action.payload.data
            })
        case "ON_TEXT_FONTTRANSFORM":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textTransform = action.payload.data
            })
        case "ON_TEXT_FONTDECO":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textDecoration = action.payload.data
            })
        case "ON_TEXT_FONTALIGN":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.textAlign = action.payload.data
            })
        case "ON_TEXT_FONTLINEHEIGHT":
            return produce(state, (draft) => {
                if (draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.lineHeight = action.payload.data
                } else {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.lineHeight = action.payload.data
                }

            })

        case "ON_TEXT_FONTCOLOR":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.color = action.payload.data
            })

        case "ON_TEXT_FONTBGCOLOR":
            return produce(state, (draft) => {
                if (draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.background = action.payload.data
                } else {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.background = action.payload.data
                }
            })

        case "REMOVE_FONTBGCOLOR":
            return produce(state, (draft) => {
                if (draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss) {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].innerCss.background = ""
                } else {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.textIndex].style.background = ""
                }

            })

        case "APPLY_TO_ALL_LAYERS":
            return produce(state, (draft) => {
                draft.campaign.slides.forEach((slide) => {
                    slide.layers.forEach((layer) => {
                        if (layer.type === "text") {
                            layer.style = action.payload.style
                            layer.innerCss = action.payload.innerCss
                        }
                    })
                })
            })


        case 'UPDATE_BG_IMAGE':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.campaign.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].type = "image"
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file.url
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.url
                }
            })

        case 'UPDATE_BG_VIDEO':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.campaign.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].type = "video"
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file.url
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file.thumbnail ? action.payload.file.thumbnail : action.payload.file.poster
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].bgDuration = action.payload.file.bgDuration
                }
            })

        case 'UPDATE_BG_COLOR':
            return produce(state, (draft) => {
                let selectedBackgroundIndex = draft.campaign.slides[action.payload.index].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBackgroundIndex !== -1) {
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].type = "color"
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].src = action.payload.file
                    draft.campaign.slides[action.payload.index].background[selectedBackgroundIndex].thumbnail = action.payload.file
                }
            })


        case "RESIZE_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size = {
                    width: action.payload.w,
                    height: action.payload.h
                }
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.x,
                    top: action.payload.y
                }
            })

        case "REPOSITION_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].position = {
                    left: action.payload.l,
                    top: action.payload.t
                }
            })

        case "UPDATE_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers.forEach((curElem, index) => {
                    if (index === action.payload.index) {
                        curElem.isSelected = true
                    } else {
                        curElem.isSelected = false
                    }
                })
            })

        case "UNSELECT_LAYERS":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
            })

        case "ADD_MEDIA_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.campaign.slides[action.payload.slideIndex].layers.push(action.payload.objData)

            })

        case "UPDATE_LAYER_IMAGE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "image"
            })

        case "UPDATE_LAYER_VIDEO":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.val.url
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].type = "video"
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case 'ADD_BGAUDIO_DATA':
            return produce(state, (draft) => {
                draft.campaign.bgAudio = {
                    ...draft.campaign.bgAudio,
                    enable: true,
                    type: "mp3",
                    source: action.payload.url,
                    duration: action.payload.dur,
                }
            })

        case 'REMOVE_BGAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.campaign.bgAudio = {
                    ...draft.campaign.bgAudio,
                    enable: false,
                    type: "",
                    source: "",
                    duration: 0,
                }
            })

        case 'UPDATE_TTS1_TEXT':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.index].audio.meta.text = action.payload.text
            })

        case 'UPDATE_GENERATED_TTS':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.index].audio.enable = true
                draft.campaign.slides[action.payload.index].audio.type = "mp3"
                draft.campaign.slides[action.payload.index].audio.duration = action.payload.val.duration
                draft.campaign.slides[action.payload.index].audio.src = action.payload.val.url
                draft.campaign.slides[action.payload.index].audio.meta.languageId = action.payload.langData.lang
                draft.campaign.slides[action.payload.index].audio.meta.voiceId = action.payload.langData.voice

                if (draft.campaign.slides[action.payload.index].faceNarration) {
                    draft.campaign.slides[action.payload.index].faceNarration.status = 1
                }

            })

        case 'ADD_TTS_DATA':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].audio.enable = action.payload.enable
                draft.campaign.slides[action.payload.slideIndex].audio.type = "mp3"
                draft.campaign.slides[action.payload.slideIndex].audio.duration = action.payload.meta.duration
                draft.campaign.slides[action.payload.slideIndex].audio.src = action.payload.meta.src
                draft.campaign.slides[action.payload.slideIndex].audio.originalSrc = action.payload.meta.originalSrc
                draft.campaign.slides[action.payload.slideIndex].audio.meta = action.payload.meta.meta

                if (draft.campaign.slides[action.payload.slideIndex].faceNarration) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.status = 1
                }

            })

        case 'REMOVE_TTSAUDIO_SLIDES':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].audio.enable = false
                draft.campaign.slides[action.payload.slideIndex].audio.type = ""
                draft.campaign.slides[action.payload.slideIndex].audio.duration = 0
                draft.campaign.slides[action.payload.slideIndex].audio.src = ''
                draft.campaign.slides[action.payload.slideIndex].audio.meta = action.payload.tts

                if (draft.campaign.slides[action.payload.slideIndex].faceNarration) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.status = 1
                }

            })

        case 'UPDATE_SLIDE_DURATION':
            return produce(state, (draft) => {
                const selectedTile = draft.campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
                draft.campaign.slides[selectedTile].duration = action.payload
                draft.campaign.slides[selectedTile].timelineData.width = action.payload * 100
            })

        case 'ADD_TEXT_LAYER':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers.forEach((curElem) => {
                    curElem.isSelected = false
                })
                draft.campaign.slides[action.payload.slideIndex].layers.push(action.payload.layer)
            })

        case "UPDATE_LAYER_TEXT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src = action.payload.text
            })

        case "REMOVE_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers.splice(action.payload.layerIndex, 1)
                if (draft.campaign.slides[action.payload.slideIndex].layers.length > 0) {
                    draft.campaign.slides[action.payload.slideIndex].layers[0].isSelected = true
                }
            })

        case "COPY_LAYER":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].isSelected = false
                if (!action.payload.layerData.layerType) {
                    action.payload.layerData = {
                        ...action.payload.layerData,
                        layerType: "custom"
                    }
                }
                draft.campaign.slides[action.payload.slideIndex].layers.push(action.payload.layerData)

            })

        case "LAYER_DOWN":
            return produce(state, (draft) => {
                let temp = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1]
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex + 1] = temp
            })

        case "LAYER_UP":
            return produce(state, (draft) => {
                let temp = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex]
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex] = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1]
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex - 1] = temp
            })

        case "DELETE_SLIDE":
            return produce(state, (draft) => {
                if (draft.campaign.slides[action.payload].isSelected === "0") {
                    draft.campaign.slides.splice(action.payload, 1)
                } else {
                    if (action.payload === 0) {
                        draft.campaign.slides[action.payload + 1].isSelected = "1"
                        draft.campaign.slides.splice(action.payload, 1)
                    }
                    else {
                        draft.campaign.slides[action.payload - 1].isSelected = "1"
                        draft.campaign.slides.splice(action.payload, 1)
                    }
                }
            })

        case 'UPDATE_SELECTED_TILES':
            return produce(state, (draft) => {
                let index = draft.campaign.slides.findIndex(({ isSelected }) => isSelected === "1");
                draft.campaign.slides[index].isSelected = "0";
                draft.campaign.slides[action.payload].isSelected = "1";
            })

        case 'UPDATE_STATUS_L&W':
            return produce(state, (draft) => {
                if (action.payload.type === 'logo') {
                    draft.campaign.logo.enable = action.payload.enable
                }
                else if (action.payload.type === 'watermark') {
                    draft.campaign.watermark.enable = action.payload.enable
                }
            })


        case 'ADD_LOGO':
            return produce(state, (draft) => {
                draft.campaign.logo.src = action.payload.data
                if (!draft.campaign.logo.pos) {
                    draft.campaign.logo.pos = "top-left"
                }
            })

        case 'ADD_WATERMARK':
            return produce(state, (draft) => {
                draft.campaign.watermark.src = action.payload.data
                if (!draft.campaign.watermark.pos) {
                    draft.campaign.watermark.pos = "top-left"
                }
            })

        case 'REMOVE_LOGO_LAYER':
            return produce(state, (draft) => {
                draft.campaign.logo.src = ''
                draft.campaign.logo.pos = 'top-left'
            })

        case 'REMOVE_WATERMARK_LAYER':
            return produce(state, (draft) => {
                draft.campaign.watermark.src = ''
                draft.campaign.watermark.pos = 'top-left'
            })

        case 'UPDATE_LOGO_POSITION':
            return produce(state, (draft) => {
                draft.campaign.logo.pos = action.payload.data
            })

        case 'UPDATE_WATERMARK_POSITION':
            return produce(state, (draft) => {
                draft.campaign.watermark.pos = action.payload.data
            })

        case 'CHANGE_BG_VOLUME':
            return produce(state, (draft) => {
                draft.campaign.bgAudio.volume = parseInt(action.payload)
            })

        case 'CHANGE_VOICE_VOLUME':
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.selectedSlideIndex].audio.volume = parseInt(action.payload.value)
            })
        case "UPDATE_LAST_ACTION":
            return produce(state, (draft) => {
                draft.campaign.lastAction = ""
            })
        case "UNDO":
            return produce(state, (draft) => {
                const slideIndex = draft.campaign.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.campaign.undoRedo[slideIndex].past.length > 0) {
                    let obj = draft.campaign.undoRedo[slideIndex].past.pop()
                    draft.campaign.undoRedo[slideIndex].future.push(draft.campaign.undoRedo[slideIndex].present)
                    draft.campaign.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.campaign.slides.findIndex(({ id }) => id === obj.id)
                    draft.campaign.slides[slideIndForSlide] = obj
                    draft.campaign.lastAction = "UNDO"
                }
            })

        case "REDO":
            return produce(state, (draft) => {
                const slideIndex = draft.campaign.undoRedo.findIndex(({ slideId }) => slideId === action.payload)
                if (slideIndex !== -1 && draft.campaign.undoRedo[slideIndex].future.length > 0) {
                    let obj = draft.campaign.undoRedo[slideIndex].future.pop()
                    draft.campaign.undoRedo[slideIndex].past.push(draft.campaign.undoRedo[slideIndex].present)
                    draft.campaign.undoRedo[slideIndex].present = obj
                    let slideIndForSlide = draft.campaign.slides.findIndex(({ id }) => id === obj.id)
                    draft.campaign.slides[slideIndForSlide] = obj
                    draft.campaign.lastAction = "REDO"
                }
            })

        case "SET_PRESENT":
            return produce(state, (draft) => {
                const slideIndex = draft.campaign.undoRedo.findIndex(({ slideId }) => slideId === action.payload.id)
                if (slideIndex !== -1) {
                    let temp = draft.campaign.undoRedo[slideIndex].present
                    temp.isSelected = "1"
                    draft.campaign.undoRedo[slideIndex].present = action.payload
                    draft.campaign.undoRedo[slideIndex].past.push(temp)
                    if (draft.campaign.undoRedo[slideIndex].future.length > 0) {
                        draft.campaign.undoRedo[slideIndex].future = []
                    }
                    draft.campaign.lastAction = ""
                }
                else {
                    draft.campaign.undoRedo.push({
                        slideId: action.payload.id,
                        past: [],
                        present: action.payload,
                        future: [],
                    })
                }
                draft.campaign.lastAction = ""
            })

        case 'SET_INTRO_STATUS':
            return produce(state, (draft) => {
                draft.campaign.intro.enable = action.payload
            })

        case 'SET_OUTRO_STATUS':
            return produce(state, (draft) => {
                draft.campaign.outro.enable = action.payload
            })

        case 'REMOVE_INTRO':
            return produce(state, (draft) => {
                draft.campaign.intro.src = ""
                draft.campaign.intro.thumbnail = ""
                draft.campaign.intro.name = ""
                draft.campaign.intro.duration = 0
            })

        case 'REMOVE_OUTRO':
            return produce(state, (draft) => {
                draft.campaign.outro.src = ""
                draft.campaign.outro.thumbnail = ""
                draft.campaign.outro.name = ""
                draft.campaign.outro.duration = 0
            })

        case 'UPDATE_INTRO':
            return produce(state, (draft) => {
                draft.campaign.intro.src = action.payload.url
                draft.campaign.intro.thumbnail = action.payload.thumbData
                draft.campaign.intro.name = action.payload.nameData
                draft.campaign.intro.duration = action.payload.duration
            })


        case 'UPDATE_OUTRO':
            return produce(state, (draft) => {
                draft.campaign.outro.src = action.payload.url
                draft.campaign.outro.thumbnail = action.payload.thumbData
                draft.campaign.outro.name = action.payload.nameData
                draft.campaign.outro.duration = action.payload.duration
            })


        case "UPDATE_TEXT_TRANSLATE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.index].audio.meta.translateText = action.payload.newText
            })

        case "REVERT_TRANSLATE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].audio.meta.translateText = ""
            })

        case "UPDATE_ANIMTION":
            return produce(state, (draft) => {
                if (action.payload.name === "animation") {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.name = action.payload.val
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "delay") {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.delay = action.payload.val
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                } else if (action.payload.name === "duration") {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.duration = action.payload.val
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = true
                }
            })

        case "ONPLAY_SLIDE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].layers.forEach(curElem => {
                    curElem.animation.playAnimation = true
                })
                draft.campaign.slides[action.payload].isPlaying = true
                draft.campaign.lastAction = "ONPLAY_SLIDE"
            })


        case "END_ANIMATION":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].animation.playAnimation = false
                draft.campaign.lastAction = "END_ANIMATION"
            })


        case "CHANGE_VIDEO_MUTE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = true
            })

        case "CHANGE_VIDEO_UNMUTE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].mute = false
            })

        case "ADD_NEW_SLIDE":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                data.audio = JSON.parse(data.audio)
                data.background = JSON.parse(data.background)
                data.faceNarration = JSON.parse(data.faceNarration)
                data.layers = JSON.parse(data.layers)
                data.layers.forEach(curElem => {
                    if (curElem.type === "text") {
                        if (!curElem.textToHighlight) {
                            curElem.textToHighlight = []
                        }
                    }
                    if (curElem.type === "video") {
                        curElem.mute = true
                    }
                })
                data.timelineData = {
                    width: data.duration * 100,
                    xVal: 0
                }
                draft.campaign.slides.push(data)
                draft.campaign.undoRedo.push({
                    slideId: data.id,
                    past: [],
                    present: data,
                    future: []
                })
            })

        case "ADD_TRANSITION":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.index].transitionName = action.payload.name
            })

        case "UPDATE_LOGO_HW":
            return produce(state, (draft) => {
                draft.campaign.logo.height = action.payload.height + "px"
                draft.campaign.logo.width = action.payload.width + "px"
                draft.campaign.lastAction = "UPDATE_LOGO_HW"
            })

        case "UPDATE_WATERMARK_HW":
            return produce(state, (draft) => {
                draft.campaign.watermark.height = action.payload.height + "px"
                draft.campaign.watermark.width = action.payload.width + "px"
                draft.campaign.lastAction = "UPDATE_WATERMARK_HW"
            })

        case "ADD_TTS_IN_ALL":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].audio.enable = true
                draft.campaign.slides[action.payload.slideIndex].audio.type = action.payload.type ? action.payload.type : "mp3"
                draft.campaign.slides[action.payload.slideIndex].audio.duration = action.payload.duration
                draft.campaign.slides[action.payload.slideIndex].audio.src = action.payload.url
                draft.campaign.slides[action.payload.slideIndex].audio.originalSrc = action.payload.originalSrc
                draft.campaign.slides[action.payload.slideIndex].audio.meta = action.payload.ttsData

                if (draft.campaign.slides[action.payload.slideIndex].duration < action.payload.duration) {
                    draft.campaign.slides[action.payload.slideIndex].duration = action.payload.duration
                }

                if (draft.campaign.slides[action.payload.slideIndex].faceNarration) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.status = 1
                }
            })

        case "REVERT_TEXT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].audio.meta.translateText = ""
            })

        case "EDIT_SCRIPT":
            return produce(state, (draft) => {
                draft.campaign.originalScript = action.payload
            })
        case "UPDATE_TEXT_HEIGHT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].size.height = action.payload.height
            })

        case "ADD_INNER_STYLE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss = action.payload.innerCss
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = ""
            })
        case "REMOVE_INNER_STYLE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss.background
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].innerCss = false
            })

        case "REPLACE_TEXT_WITH_STYLE":
            return produce(state, (draft) => {
                if (draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].src.includes(action.payload.data.text)) {

                    let obj = {
                        text: action.payload.data.text,
                        color: action.payload.data.color,
                        background: action.payload.data.background
                    }
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight.push(obj)

                }
            })

        case "UPDATE_HIGHLIGHT":
            return produce(state, (draft) => {
                if (action.payload.type === "color") {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.index].color = action.payload.color
                } else {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.index].background = action.payload.color
                }
            })

        case "REMOVE_HIGHLIGHT":
            return produce(state, (draft) => {
                if (action.payload.type === "color") {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.arrIndex].color = ""
                } else {
                    draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].textToHighlight[action.payload.arrIndex].background = ""
                }
            })

        case "REMOVE_TRANSITION":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].transitionName = ""
            })


        case "CHANGE_VARIATION_DATA":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex] = action.payload.obj
            })

        case "UPDATE_ORIGINAL_SCRIPT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.i].originalScript = action.payload.text
            })

        case "UPDATE_LAYER_ORIGINAL_SCRIPT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.i].layers[action.payload.indexVal].src = action.payload.text
            })

        case "ENABLE_PROMO":
            return produce(state, (draft) => {
                draft.campaign.promo.enable = action.payload
            })

        case "UPDATE_POSITION_PROMO":
            return produce(state, (draft) => {
                draft.campaign.promo.position = action.payload
            })


        case "UPDATE_CTA_PROMO":
            return produce(state, (draft) => {
                draft.campaign.promo.data = action.payload
            })

        case "TOGGLE_TALKING_HEAD":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.enable = action.payload.checked
            })

        case "TOGGLE_HEAD_MOVEMENT":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.headMovement = action.payload.checked
                draft.campaign.slides[action.payload.slideIndex].faceNarration.status = 1
            })
        case "UPDATE_TAKING_MEDIA":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.media.url = action.payload.url
                draft.campaign.slides[action.payload.slideIndex].faceNarration.media.type = action.payload.type
                draft.campaign.slides[action.payload.slideIndex].faceNarration.status = 1
                if (!draft.campaign.slides[action.payload.slideIndex].faceNarration.media.pos) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.media.pos = "bottom-right"
                }
                if (!draft.campaign.slides[action.payload.slideIndex].faceNarration.media.height) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.media.height = 125
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.media.width = 125
                }
                if (!draft.campaign.slides[action.payload.slideIndex].faceNarration.media.shape) {
                    draft.campaign.slides[action.payload.slideIndex].faceNarration.media.shape = "circle"
                }
            })

        case "UPDATE_TALKING_POSITIONS":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.campaign.slides[action.payload.slideIndex].faceNarration.media,
                    pos: action.payload.val
                }
            })

        case "UPDATE_TALKING_SIZE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.campaign.slides[action.payload.slideIndex].faceNarration.media,
                    height: action.payload.val,
                    width: action.payload.val
                }
            })
        case "UPDATE_TALKING_SHAPE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.slideIndex].faceNarration.media = {
                    ...draft.campaign.slides[action.payload.slideIndex].faceNarration.media,
                    shape: action.payload.val
                }
            })

        case "ON_DELETE_BACK":
            return produce(state, (draft) => {
                if (+draft.campaign.slides[action.payload.slideIndex].background[action.payload.backIndex].isSelected === 1) {
                    if (action.payload.backIndex > 0) {
                        draft.campaign.slides[action.payload.slideIndex].background[action.payload.backIndex - 1].isSelected = 1
                    } else {
                        draft.campaign.slides[action.payload.slideIndex].background[action.payload.backIndex + 1].isSelected = 1
                    }
                }
                draft.campaign.slides[action.payload.slideIndex].background.splice(action.payload.backIndex, 1)
            })


        case "ADDING_A_SLIDE":
            return produce(state, (draft) => {
                action.payload.forEach((curElem) => {
                    let isSLideAvaliabel = draft.campaign.slides.findIndex(({ id }) => +id === +curElem.id)
                    if (isSLideAvaliabel === -1) {
                        let obj = { ...curElem }
                        obj.layers.forEach((layer) => {
                            if (!layer.textToHighlight && layer.type === "text") {
                                layer.textToHighlight = []
                            }
                            if (layer.type === "video" && layer.mute === undefined) {
                                layer.mute = true
                            }
                            if (layer.type === "text") {
                                if (layer.style.lineHeight === "") {
                                    layer.style.lineHeight = 1.4
                                }
                            }
                        })
                        obj.timelineData = {
                            width: obj.duration * 100,
                            xVal: 0
                        }
                        draft.campaign.undoRedo.push({
                            slideId: obj.id,
                            past: [],
                            present: obj,
                            future: []
                        })
                        draft.campaign.slides.push(obj)
                    }
                })
            })

        case "STOP_PLAYING_SLIDE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload].isPlaying = false
            })


        case "ON_SELECT_INNERBACK":
            return produce(state, (draft) => {
                let selectedBack = draft.campaign.slides[action.payload.slideIndex].background.findIndex(({ isSelected }) => +isSelected === 1)
                if (selectedBack !== -1) {
                    draft.campaign.slides[action.payload.slideIndex].background[selectedBack].isSelected = 0
                    draft.campaign.slides[action.payload.slideIndex].background[action.payload.backgroundIndex].isSelected = 1
                }
            })

        case "CHANGE_BG_INTERVAL":
            return produce(state, (draft) => {
                let background = draft.campaign.slides[action.payload].background
                let bgSelectedIndex = background.findIndex(({ isSelected }) => +isSelected === 1)

                bgSelectedIndex = bgSelectedIndex === -1 ? 0 : bgSelectedIndex
                background[bgSelectedIndex].isSelected = 0
                if (bgSelectedIndex === background.length - 1) {
                    background[0].isSelected = 1
                } else {
                    background[bgSelectedIndex + 1].isSelected = 1
                }
            })

        case "GRADIENT_LEFT":

            return produce(state, (draft) => {
                let bg = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background
                bg = bg.split(",")
                let newArr = bg[1].split(" ")
                newArr[1] = action.payload.hex
                newArr = newArr.join(" ")
                bg[1] = newArr
                let gradient = bg.join(",")
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = gradient
            })

        case "GRADIENT_RIGHT":
            return produce(state, (draft) => {
                let bg = draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background
                bg = bg.split(",")
                let newArr = bg[2].split(" ")
                newArr[1] = action.payload.hex
                newArr = newArr.join(" ")
                bg[2] = newArr
                let gradient = bg.join(",")
                draft.campaign.slides[action.payload.slideIndex].layers[action.payload.layerIndex].style.background = gradient
            })


        case "UNMOUNT_EDITOR":
            return produce(state, (draft) => {
                draft.campaign = false
            })

        // video tile drag things

        case "DRAG_RIGHT":
            return produce(state, (draft) => {
                let val = action.payload.lastWidth + action.payload.movementVal - action.payload.deviderVal
                draft.campaign.slides[action.payload.index].timelineData.width = val
                if ((val / 100) >= 1) {
                    draft.campaign.slides[action.payload.index].duration = val / 100
                }
            })

        case "ON_CHANGE_CAMP_NAME":
            return produce(state, (draft) => {
                draft.campaign.name = action.payload
            })

        case "ON_UPDATE_CLONE_VOICE":
            return produce(state, (draft) => {
                draft.campaign.slides[action.payload.selectedSlideIndex].audio.src = action.payload.urr.url
                draft.campaign.slides[action.payload.selectedSlideIndex].audio.duration = action.payload.urr.duration
                draft.campaign.slides[action.payload.selectedSlideIndex].audio.status = "completed"
            })

        default:
            return state
    }

}