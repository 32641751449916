import React from 'react'
import { useDispatch } from 'react-redux'
import { onFontFamily, onFontSize, onTextAlign, onTextBold, onTextItalic, onTextLineHeight, onTextLower, onTextStrike, onTextUnderline, onTextUpper } from '../../../actions/contentAction'

const FontStyle = ({ selectedText, styleState, selectedSlide, slideIndex, setSlideAll, fonts }) => {
    const dispatch = useDispatch()
    const textIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    const onTextStyle = (type) => {
        setSlideAll(false);
        if (type === 'bold') {
            if (styleState.fontWeight === false || styleState.fontWeight === "") {
                dispatch(onTextBold("bold", slideIndex, textIndex));
            } else {
                dispatch(onTextBold(false, slideIndex, textIndex));
            }
        } else if (type === 'italic') {
            if (styleState.fontStyle === false || styleState.fontStyle === "") {
                dispatch(onTextItalic("italic", slideIndex, textIndex));
            } else {
                dispatch(onTextItalic(false, slideIndex, textIndex));
            }
        } else if (type === 'uppercase') {
            if (styleState.textTransform === "uppercase") {
                dispatch(onTextUpper("none", slideIndex, textIndex));
            } else {
                dispatch(onTextUpper("uppercase", slideIndex, textIndex));
            }
        } else if (type === 'lowercase') {
            if (styleState.textTransform === "lowercase") {
                dispatch(onTextLower("none", slideIndex, textIndex));
            } else {
                dispatch(onTextLower("lowercase", slideIndex, textIndex));
            }
        } else if (type === 'underline') {
            if (styleState.textDecoration === "underline") {
                dispatch(onTextUnderline("none", slideIndex, textIndex));
            } else {
                dispatch(onTextUnderline("underline", slideIndex, textIndex));
            }
        } else if (type === 'line-through') {
            if (styleState.textDecoration === "line-through") {
                dispatch(onTextStrike("none", slideIndex, textIndex));
            } else {
                dispatch(onTextStrike("line-through", slideIndex, textIndex));
            }
        }
    }

    const onInputChange = (e, type) => {
        setSlideAll(false);
        if (type === 'size') {
            dispatch(onFontSize(e.target.value, slideIndex, textIndex));
        } else if (type === 'font_family') {
            dispatch(onFontFamily(e.target.value, slideIndex, textIndex));
        }
    }

    const onAlign = (align) => {
        setSlideAll(false);
        dispatch(onTextAlign(align, slideIndex, textIndex));
    }

    const onChangeLineHeight = (e) => {
        setSlideAll(false);
        dispatch(onTextLineHeight(e.target.value, slideIndex, textIndex))
    }

    return (
        <div className="fontStyle fontStyle-block-wrap">
            <h2>Font Style</h2>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-8">
                        <select
                            id="sel-font"
                            name="font_family"
                            className='cursor-pointer text-hover-effect'
                            value={styleState.fontFamily}
                            style={{ fontFamily: styleState.fontFamily }}
                            onChange={(e) => onInputChange(e, 'font_family')}
                        >
                            <option  value="" selected>Select Font</option>
                            {
                                fonts.length > 0 ?
                                    fonts.map((font, index) => {
                                        return (
                                            <option style={{ fontFamily: font }}
                                                value={font} key={index}>
                                                {font}
                                            </option>
                                        )
                                    })
                                    : ''
                            }
                        </select>
                    </div>
                    <div className="col-4">
                        <input
                            id="f-size"
                            type="number"
                            className="form-control edit-input-font-size text-hover-effect"
                            name="f-size"
                            min="10"
                            value={styleState.fontSize}
                            onChange={(e) => onInputChange(e, 'size')}
                        />
                    </div>
                </div>
            </div>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-2">
                        <div
                            className={`${styleState.fontWeight === "bold" ? 'font-active' : ''}   font-format cursor-pointer text-hover-effect `}
                            onClick={() => onTextStyle('bold')}
                            data-toggle="tooltip"
                            title="Text Bold"
                        ><span className="font-weight-bold">T</span>
                        </div>

                    </div>
                    <div className="col-2">
                        <div
                            className={`${styleState.fontStyle === "italic" ? 'font-active' : ''} font-format cursor-pointer text-hover-effect`}
                            onClick={() => onTextStyle('italic')}
                            data-toggle="tooltip"
                            title="Text Italic"
                        ><span className="font-italic">T</span>
                        </div>

                    </div>
                    <div className="col-2">
                        <div
                            className={`${styleState.textTransform === "uppercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onTextStyle('uppercase')}
                            data-toggle="tooltip"
                            title="Text Uppercase"
                        ><span>TT</span></div>
                    </div>

                    <div className="col-2">
                        <div
                            className={`${styleState.textTransform === "lowercase" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onTextStyle('lowercase')}
                            data-toggle="tooltip"
                            title="Text Lowercase"
                        ><span>tt</span></div>
                    </div>

                    <div className="col-2">
                        <div
                            className={`${styleState.textDecoration === "underline" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onTextStyle('underline')}
                            data-toggle="tooltip"
                            title="Text Underline"
                        ><span className="text-decoration-underline">T</span></div>
                    </div>

                    <div className="col-2">
                        <div
                            className={`${styleState.textDecoration === "line-through" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onTextStyle('line-through')}
                            data-toggle="tooltip"
                            title="Text Strike"
                        ><span className="text-decoration-line-through">T</span></div>
                    </div>

                </div>
            </div>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-2">
                        <div
                            className={`${styleState.textAlign === "left" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onAlign('left')}
                            data-toggle="tooltip"
                            title="Text Left"
                        >
                            <i className="fa fa-align-left" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div className="col-2">

                        <div
                            className={`${styleState.textAlign === "center" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onAlign('center')}
                            data-toggle="tooltip"
                            title="Text Center"
                        ><i className="fa fa-align-center" aria-hidden="true"></i></div>
                    </div>

                    <div className="col-2">

                        <div
                            className={`${styleState.textAlign === "right" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onAlign('right')}
                            data-toggle="tooltip"
                            title="Text Right"
                        ><i className="fa fa-align-right" aria-hidden="true"></i></div>
                    </div>

                    <div className="col-2">

                        <div
                            className={`${styleState.textAlign === "justify" ? 'font-active' : ''}  font-format cursor-pointer text-hover-effect`}
                            onClick={() => onAlign('justify')}
                            data-toggle="tooltip"
                            title="Text Justify"
                        ><i className="fa fa-align-justify" aria-hidden="true"></i></div>
                    </div>

                    <div className="col-4">

                        <div
                            className={'form-format'}
                            data-toggle="tooltip"
                            title="Text Line Height"
                        >
                            <input
                                id="t-lineheight"
                                className="edit-input-lineheight text-hover-effect"
                                type="number"
                                name="Lineheight"
                                value={selectedText.innerCss ? selectedText.innerCss.lineHeight : styleState.lineHeight}
                                min={1}
                                step="0.1"
                                onChange={(e) => onChangeLineHeight(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FontStyle