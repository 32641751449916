import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { onUpdatePassword, updatePassword } from "../../actions/authAction";

const ProfilePassword = () => {

  const dispatch = useDispatch();

  const [loader, setLoader] = useState({
    password: false,
  })

  const [passwordMsg, setPasswordMsg] = useState({
    msg: "",
    validation: false
  })


  const [userDetails, setUserDetails] = useState({
    password: '',
    new_password: '',
    confirm_password: '',
  });

  const onInputChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  }

  // const onFormSubmit = (e) => {
  //     e.preventDefault();
  //     setLoader(true);
  //     dispatch(updatePassword(userDetails, setLoader));

  // }


  const updatePassword = (e) => {
    e.preventDefault()
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
    if (userDetails.new_password) {
      if (pattern.test(userDetails.new_password)) {
        if (userDetails.new_password === userDetails.confirm_password) {
          setLoader({
            ...loader,
            password: true
          })
          dispatch(onUpdatePassword(userDetails, loader, setLoader))
        } else {
          setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
        }

      } else {
        setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
      }
    }
  }


  return (
    <>


      <div className="profileBlock">
        <h2 className="text-center">Update Password</h2>
        <form className="" method="post" onSubmit={updatePassword}>
          <div className="form-group form-group-alt">
            <label className="form-text text-muted"><span tooltip="Input your current password for verification purposes." tooltip-position="right">Current Password</span></label>
            <input
              type="password"
              className="form-control"
              placeholder="**********"
              name="password"
              required
              onChange={(e) => onInputChange(e)}
              maxLength={32}
              minLength={6}
            />
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="form-group form-group-alt">
                <label className="form-text text-muted"><span tooltip="Select a new password for your account security." tooltip-position="right">New Password</span></label>

                <input
                  type="password"
                  className="form-control"
                  placeholder="**********"
                  name="new_password"
                  required
                  onChange={(e) => onInputChange(e)}
                  maxLength={32}
                  minLength={6}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group form-group-alt">
                <label className="form-text text-muted"><span tooltip="Re-enter the new password for confirmation." tooltip-position="right">Confirm New Password</span></label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="**********"
                  name="confirm_password"
                  required
                  onChange={(e) => onInputChange(e)}
                  maxLength={32}
                  minLength={6}
                />
              </div>
            </div>
          </div>
          <div class="alert alert-info my-2 text-center py-2" role="alert">
            Updating your password will log you out for re-login
          </div>
          {passwordMsg.validation ?
            <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
          <button type="submit" className="fbtn btn-block demoLink mt-1" disabled={loader.password}> {loader.password ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update</button>

        </form>
      </div>
    </>
  )
}

export default ProfilePassword;